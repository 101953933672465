import React, { useState, useEffect } from "react"
import logo from "../assets/images/logo_main.svg"

import {
  Container,
  Navbar,
  Nav,
  NavItem,
  NavDropdown,
  MenuItem,
} from "react-bootstrap"
import Button from "react-bootstrap/Button"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"

import { Link } from "gatsby"
import { useLocation } from "@reach/router"

import "../assets/styles/global.scss"

import { OutboundLink } from "gatsby-plugin-google-gtag"

import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"

library.add(fab, fas)

const NavBar = ({ version }) => {
  const [about, setAbout] = useState(false)
  const [suppliers, setSuppliers] = useState(false)
  const [blog, setBlog] = useState(false)
  const [contact, setContact] = useState(false)
  const [jobs, setJobs] = useState(false)

  const [activeMobileNav, setActiveMobileNav] = useState(false)

  useEffect(() => {
    const currentPath = window.location.pathname.split("/")[1]

    if (currentPath === "about") {
      setAbout(true)
    } else if (currentPath === "suppliers") {
      setSuppliers(true)
    } else if (currentPath === "blog") {
      setBlog(true)
    } else if (currentPath === "contact") {
      setContact(true)
    } else if (currentPath === "jobs") {
      setJobs(true)
    }
  }, [])

  const location = useLocation()

  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Coming Soon!
    </Tooltip>
  )

  return (
    <nav className={`${location.pathname === "/" ? "light" : ""}`}>
      <Container>
        <div className="nav--web d-flex justify-content-between align-items-center w-100">
          <div className="navbar--logo--container">
            <Link to="/">
              <img src={logo} className="nav__logo" alt="dk_logo" />
            </Link>
          </div>
          <div className="navbar--links--container">
            <ul className="p-0 m-0">
              <li className="nav__list">
                <Link
                  to="/about"
                  className={`nav__link ${about ? "active" : ""}`}
                >
                  <h6>About</h6>
                </Link>
              </li>
              <li className="nav__list">
                <Link
                  to="/suppliers"
                  className={`nav__link ${suppliers ? "active" : ""}`}
                >
                  <h6>Suppliers</h6>
                </Link>
              </li>
              <li className="nav__list">
                <Link
                  to="/jobs"
                  className={`nav__link ${jobs ? "active" : ""}`}
                >
                  <h6>Jobs</h6>
                </Link>
              </li>
              {/* <li className="nav__list">
                <Link
                  to="/events"
                  className={`nav__link ${blog ? "active" : ""}`}
                >
                  <h6>Events</h6>
                </Link>
              </li> */}
              <li className="nav__list">
                <Link
                  to="/contact"
                  className={`nav__link ${contact ? "active" : ""}`}
                >
                  <h6>Contact</h6>
                </Link>
              </li>
            </ul>
          </div>
          <div className="navbar--cta--container d-flex justify-content-center">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 100, hide: 100 }}
              overlay={renderTooltip}
            >
              <OutboundLink
                href="https://shop.cafekraken.com/"
                target="_blank"
                className="btn btn--persian--green nav__link nav--cta"
                onClick={event => event.preventDefault()}
              >
                Order Now
              </OutboundLink>
            </OverlayTrigger>
          </div>
        </div>
      </Container>

      <div className="nav--mobile">
        <div className="navbar--logo--container">
          <Link to="/">
            <img src={logo} className="nav__logo" alt="dk_logo" />
          </Link>
        </div>
        <input
          type="checkbox"
          id="overlay-input"
          onChange={() => setActiveMobileNav(!activeMobileNav)}
        />
        <label for="overlay-input" id="overlay-button">
          <span></span>
        </label>

        <div id="overlay" className={activeMobileNav ? "active" : ""}>
          <ul>
            <li className="nav__list">
              <Link to="/" className={`nav__link`}>
                <h6>Homepage</h6>
              </Link>
            </li>
            <li className="nav__list">
              <Link to="/about" className={`nav__link`}>
                <h6>About</h6>
              </Link>
            </li>
            <li className="nav__list">
              <Link to="/suppliers" className={`nav__link`}>
                <h6>Suppliers</h6>
              </Link>
            </li>
            <li className="nav__list">
              <Link to="/jobs" className={`nav__link`}>
                <h6>Jobs</h6>
              </Link>
            </li>
            <li className="nav__list">
              <Link to="/contact" className={`nav__link`}>
                <h6>Contact</h6>
              </Link>
            </li>

            {/* <li className="nav__list">
              <a
                href="https://shop.cafekraken.com/"
                target="_blank"
                className="btn nav__link"
              >
                Order
              </a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default NavBar
